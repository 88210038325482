/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ErrorBoundaryWithMessages } from '@newfront-insurance/admin-ui/layout';
import React from 'react';

type ErrorMessages = Parameters<typeof ErrorBoundaryWithMessages>[0]['messages'];

interface Options {
  messages: ErrorMessages;
  onError: (error: Error) => unknown;
}

export interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createErrorBoundary(options: Options) {
  const { messages, onError } = options;

  function ErrorBoundary(props: Props): JSX.Element {
    const { children } = props;

    return (
      <ErrorBoundaryWithMessages messages={messages} onError={onError}>
        {children}
      </ErrorBoundaryWithMessages>
    );
  }

  return ErrorBoundary;
}
