/* eslint-disable no-process-env */
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import developmentConfig from '@newfront-insurance/shared-public-config/dist/development';
import productionConfig from '@newfront-insurance/shared-public-config/dist/production';
import defaultConfig from '@newfront-insurance/shared-public-config/dist/staging';
import merge from 'lodash/merge';

import customDevelopmentConfig from './development.json';
import customProductionConfig from './production.json';
import customDefaultConfig from './staging.json';

type CustomConfigType = typeof customDefaultConfig;

let config: ConfigType & CustomConfigType;

/**
 * We handle the config this way so that Webpack will tree-shake the unused config
 * from the final build on the client.
 */
if (process.env.NEXT_PUBLIC_CONFIG_ENV === 'production') {
  config = { ...productionConfig, ...customProductionConfig };
} else if (process.env.NEXT_PUBLIC_CONFIG_ENV === 'development') {
  config = merge(developmentConfig, customDevelopmentConfig);
} else {
  config = { ...defaultConfig, ...customDefaultConfig };
}

export function getConfig(): ConfigType & CustomConfigType {
  return config;
}
