import { createAuthProvider, createAuthSwapProvider, IDPHint } from '@newfront-insurance/next-auth';

import { getConfig } from '@/config';

const { API, KEYCLOAK } = getConfig();

export const AuthProvider = createAuthProvider({
  idpHint: IDPHint.GSUITE,
  authApiConfig: {
    clientId: KEYCLOAK.CLIENT_ID,
    realm: KEYCLOAK.REALM.CLIENTS,
    url: API.AUTH,
  },
});

export const AuthSwapProvider = createAuthSwapProvider({
  authProvider: AuthProvider,
  clientId: 'newfront-web-target-account',
});
