import * as Sentry from '@sentry/nextjs';

import { getConfig } from './src/config';

const { SENTRY } = getConfig();

Sentry.init({
  dsn: SENTRY.DSN.CLIENT_DASH,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: SENTRY.ENABLED,
  environment: SENTRY.ENVIRONMENT,
});
