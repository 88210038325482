import { AnalyticsProvider as BaseAnalyticsProvider } from '@newfront-insurance/data-layer-client';
import { useQuery, useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import React, { useMemo } from 'react';

import { AuthProvider } from './auth';

import { getConfig } from '@/config';

const { SEGMENT } = getConfig();

interface Props {
  children: React.ReactNode;
}

export function AnalyticsProvider(props: Props): JSX.Element {
  const { children } = props;
  const { userDetails } = useProvider(AuthProvider);
  const { router } = useRouter();
  const accountUuid = useQuery<string>('accountUuid');
  const { route } = router;

  const user = useMemo(() => {
    if (!userDetails) return undefined;
    return {
      userId: userDetails.uuid,
      traits: {
        email: userDetails.email,
      },
    };
  }, [userDetails]);

  // These will be automatically added to all track and page events.
  const defaultPageProperties = useMemo(() => {
    return {
      accountUuid,
    };
  }, [accountUuid]);

  const defaultTrackProperties = useMemo(() => {
    return {
      accountUuid,
      route,
    };
  }, [accountUuid, route]);

  return (
    <BaseAnalyticsProvider
      apiKey={SEGMENT.SOURCES.CLIENT_DASH}
      user={user}
      router={router}
      appName="client-dash-app"
      defaultTrackProperties={defaultTrackProperties}
      defaultPageProperties={defaultPageProperties}
    >
      {children}
    </BaseAnalyticsProvider>
  );
}
