import { useProvider } from '@newfront-insurance/react-provision';
import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';

import { AuthProvider } from './auth';

interface Props {
  children: React.ReactNode;
}

export function SentryUserProvider({ children }: Props): JSX.Element {
  const { userDetails } = useProvider(AuthProvider);

  useEffect(() => {
    if (userDetails) {
      Sentry.setUser({ email: userDetails.email, id: userDetails.uuid });
    }
  }, [userDetails]);

  return <>{children}</>;
}
