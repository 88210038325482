/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createProvider } from '@newfront-insurance/react-provision';
import { useMedia } from 'react-use';

import { MOBILE_BREAKPOINT } from '../helpers/constants';

export const ResponsiveProvider = createProvider(
  () => {
    const isMobile = useMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`);
    return { isMobile };
  },
  {
    name: 'ResponsiveProvider',
  },
);
